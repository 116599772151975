import React, { FC } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import classNames from 'classnames';
import { CTUI } from '@thelearningcorp/ct-web-packages';
import { useForm } from 'App/survey/endOfTrial/useForm';

import styles from './form.module.scss';

const feedbackOptions = [
  {
    value: 'not_right_now',
    label: 'Interested in Constant Therapy, just not at this time',
  },
  {
    value: 'not_sure',
    label: 'Not sure that Constant Therapy will be beneficial',
  },
  {
    value: 'too_difficult',
    label: 'Too difficult to use',
  },
  {
    value: 'too_easy',
    label: 'The exercises were not challenging enough',
  },
  {
    value: 'no_device',
    label: 'Don’t have an appropriate device to use',
  },
  {
    value: 'too_expensive',
    label: 'Too expensive',
  },
  {
    value: 'other',
    label: `Other: <input type='text' name='otherMessageFake' class="${styles.otherMessage}" />`,
  },
];

const EOTFormComponent: FC<InjectedFormProps> = ({
  handleSubmit,
  error,
  pristine,
  submitting,
  change,
}) => {
  useForm({ change });

  return (
    <CTUI.Form.Form
      handleSubmit={handleSubmit}
      actions={
        <CTUI.Form.SubmitButton
          text="Submit"
          isSubmitting={submitting}
          isPristine={pristine}
          customClasses={styles.button}
        />
      }
      actionsWrapperClasses={styles.formActions}
      error={error}
      customClasses={styles.eotForm}
    >
      <h1 className={classNames('h1 medium', styles.title)}>Constant Therapy Feedback Form</h1>
      <p>
        Thank you for trying Constant Therapy! We noticed you chose not to continue to use Constant
        Therapy after your trial. To understand how we can serve you and others better, please
        answer one quick question below.
      </p>
      <p>Your feedback is invaluable and we appreciate your time and support.</p>
      <p>
        Please share why you’re not ready to move forward with Constant Therapy for your at-home
        speech, cognitive and/or language therapy:
      </p>
      <Field
        component={CTUI.Form.CheckboxGroup}
        name="feedback"
        options={feedbackOptions}
        type="checkboxGroup"
        customClasses={styles.checkboxes}
      />
      <Field component={CTUI.Form.Field} name="otherMessage" type="hidden" />
      <Field
        component={CTUI.Form.Textarea}
        name="extra"
        type="textarea"
        label="Is there anything else you’d like to share?"
        placeholder="Your answer here."
        customClasses={styles.textarea}
        required
      />
    </CTUI.Form.Form>
  );
};

const EOTForm = reduxForm({ form: 'eotForm' })(EOTFormComponent);

export { EOTForm };
