// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC04qvWpj8Ny6fgCJrgvm5oNBSXJFy76zY',
  authDomain: 'constant-therapy-b5470.firebaseapp.com',
  databaseURL: 'https://constant-therapy-b5470.firebaseio.com',
  projectId: 'constant-therapy-b5470',
  storageBucket: 'constant-therapy-b5470.firebasestorage.app',
  messagingSenderId: '923544178940',
  appId: '1:923544178940:web:82872217ad967d7124c779',
  measurementId: 'G-T05SR68PN7',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
