import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { logEvent } from 'Shared/actions/event';
import { selectUserType } from 'Shared/actions/signup';
import { USER_TYPES } from 'Shared/types/shared';
import { SIGNUP } from 'Shared/types/events';
import { BUILT_IN_EVENTS, SIGNUP_EVENTS, trackEvent } from 'Shared/src/scripts/firebase/analytics';

export function useUserTypeSelection() {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();

  const handleClick = (userType: USER_TYPES) => {
    trackEvent(SIGNUP_EVENTS.USER_TYPE_SELECTED, { type: userType });

    void logEvent({
      page: SIGNUP.USER_TYPE_SELECTED,
      nonInteraction: false,
    })(dispatch, store.getState.bind(store));

    selectUserType(userType)(dispatch);
  };

  useEffect(() => {
    trackEvent(BUILT_IN_EVENTS.PAGE_VIEW, { page_path: SIGNUP.USER_TYPE_SELECTION });

    void logEvent({
      page: SIGNUP.USER_TYPE_SELECTION,
      nonInteraction: true,
    })(dispatch, store.getState.bind(store));
  }, []);

  return { handleClick };
}
