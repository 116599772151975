import React, { FC } from 'react';

import { USER_TYPES } from 'Shared/types/shared';
import downloadAppStoreImage from 'Shared/src/images/downloadAppStore.png';
import downloadPlayStoreImage from 'Shared/src/images/downloadPlayStore.png';
import downloadAmazonAppsImage from 'Shared/src/images/downloadAmazonApps.png';

import { useDownloadAppList, DownloadAppListProps } from './useDownloadAppList';

import styles from './index.module.scss';

export const DownloadAppList: FC<DownloadAppListProps> = ({ userType = USER_TYPES.PATIENT }) => {
  const { appStoreLink, playStoreLink, amazonLink, handleOutboundClick } = useDownloadAppList({
    userType,
  });

  return (
    <ul className={styles.downloadAppList}>
      <li className={styles.item}>
        <a
          href={appStoreLink}
          onClick={() => {
            handleOutboundClick(appStoreLink);
          }}
        >
          <img
            src={downloadAppStoreImage}
            alt="Download the Constant Therapy app on the App Store"
            title="Download the Constant Therapy app on the App Store"
          />
        </a>
      </li>
      <li className={styles.item}>
        <a
          href={playStoreLink}
          onClick={() => {
            handleOutboundClick(playStoreLink);
          }}
        >
          <img
            src={downloadPlayStoreImage}
            alt="Download the Constant Therapy app on the Google Play"
            title="Download the Constant Therapy app on the Google Play"
          />
        </a>
      </li>
      <li className={styles.item}>
        <a
          href={amazonLink}
          onClick={() => {
            handleOutboundClick(amazonLink);
          }}
        >
          <img
            src={downloadAmazonAppsImage}
            alt="Download the Constant Therapy app on the Amazon"
            title="Download the Constant Therapy app on the Amazon"
          />
        </a>
      </li>
    </ul>
  );
};
