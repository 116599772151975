import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Meta } from './meta';
import { HowAreYourTasksWizard } from './howAreYourTasks';
import { EndOfTrial } from './endOfTrial';

export const Survey: FC = () => {
  return (
    <Switch>
      <Route exact path="/survey/campaign/how_are_your_tasks">
        <Meta />
        <HowAreYourTasksWizard />
      </Route>
      <Route exact path="/survey/campaign/end_of_trial">
        <Meta />
        <EndOfTrial />
      </Route>
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
};
