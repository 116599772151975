import React from 'react';

import { Page } from 'Shared/components/layout/landingLayout/page';

import { EOTForm } from './form';
import { useEndOfTrial } from './useEndOfTrial';
import { Thanks4Feedback } from './thanks4Feedback';

export const EndOfTrial = () => {
  const { isSubmitted, handleSubmit } = useEndOfTrial();

  return <Page>{isSubmitted ? <Thanks4Feedback /> : <EOTForm onSubmit={handleSubmit} />}</Page>;
};
