import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { useCallback, useEffect } from 'react';

import { SurveyCampaignEndOfTrialForm } from 'Shared/types/forms';

import styles from 'App/survey/endOfTrial/form.module.scss';

export function useForm({ change }: { change: (field: string, value: any) => void }) {
  const formValues = useSelector(getFormValues('eotForm')) as SurveyCampaignEndOfTrialForm;

  const handleFakeFieldFocus = useCallback(() => {
    change('feedback', [...((formValues && formValues['feedback']) || []), 'other']);
  }, [formValues]);
  const handleFakeFieldChange = useCallback((e: KeyboardEvent) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    change('otherMessage', value);
  }, []);

  useEffect(() => {
    const otherMessage = document.querySelector(`.${styles.otherMessage}`);
    if (otherMessage) {
      otherMessage.addEventListener('focus', handleFakeFieldFocus);
      otherMessage.addEventListener('keyup', handleFakeFieldChange);
    }
  }, [formValues]);
}
