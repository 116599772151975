import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import logoImage from 'Shared/src/themes/2023/images/logo.svg';
import { HTML_HEADER_ID, PARTNER_LOGO_STORAGE_NAME } from 'Shared/constants';

import { LoginStatusBar } from './loginStatusBar';

import styles from './index.module.scss';

interface HeaderProps {
  action?: string;
}

const mountingPoint = document.getElementById(HTML_HEADER_ID);

export const Header: FC<HeaderProps> = ({ action }) => {
  const { pathname } = useLocation();
  const isDev = process.env.ENV === 'dev';
  const isStaging = process.env.ENV === 'staging';
  const envLogoClasses = classNames(process.env.ENV, styles.envLogo);
  const logo = sessionStorage.getItem(PARTNER_LOGO_STORAGE_NAME);

  return ReactDOM.createPortal(
    <div className={styles.header}>
      <a href="/" className={styles.logoWrapper}>
        {isDev && <span className={envLogoClasses}>DEV</span>}
        {isStaging && <span className={envLogoClasses}>STAGE</span>}
        <img
          src={logoImage as string}
          alt="Constant Therapy"
          title="Constant Therapy"
          className={styles.logo}
        />
      </a>
      {action === 'flint' && (
        <img
          src="/shared/src/themes/2019/images/flint-rehab-logo.png"
          alt="Flint Rehab Logo"
          title="Flint Rehab Logo"
          className={styles.flintLogo}
        />
      )}
      {pathname === '/signup/' ? (
        logo && <img src={logo} className={styles.partnerLogo} alt="Partner Logo" />
      ) : (
        <LoginStatusBar />
      )}
    </div>,
    mountingPoint
  );
};
