import React, { FC } from 'react';
import classNames from 'classnames';

import feedbackImage from './feedback.svg';

import styles from './thanks4Feedback.module.scss';

export const Thanks4Feedback: FC = () => {
  return (
    <div className={styles.thanks4Feedback}>
      <img src={feedbackImage as string} alt="Feedback" title="Feedback" className={styles.image} />
      <h1 className={classNames('h1 medium', styles.title)}>Thank you for your feedback!</h1>
      <p>
        Your feedback will help to improve Constant Therapy for you and other patients like you.
      </p>
    </div>
  );
};
