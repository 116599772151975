import { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { logEvent } from 'Shared/actions/event';
import { GA_EVENTS, SIGNUP } from 'Shared/types/events';
import {
  selectUserType,
  setEmail,
  setInstitutionId,
  setInvitationToken,
  setSource,
} from 'Shared/actions/signup';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { PasswordRequirements, RootState, USER_TYPES } from 'Shared/types/shared';
import { getPasswordRequirements } from 'Shared/actions/institution';

export function useSignup() {
  const store = useStore();
  const dispatch: Dispatch = useDispatch();
  const queryParams = useQueryParams();
  const [passwordRequirements, setPasswordRequirements] = useState({});
  const signupData = useSelector((state: RootState) => state.signup);

  const fetchPasswordRequirements = async (institutionId: number) => {
    try {
      const passwordRequirements = (await getPasswordRequirements(institutionId)(
        dispatch
      )) as PasswordRequirements;
      setPasswordRequirements(passwordRequirements);
    } catch (e: unknown) {
      console.log(e);
    }
  };

  //IMPORTANT: Order of dispatched actions matters!
  useEffect(() => {
    /**
     * Added according to BIANE signup flow requirements to bypass user-type selection screen
     * @see {@link https://www.pivotaltracker.com/story/show/163429292}
     * It's also appeared to be useful for other leadsources @see {@link https://www.pivotaltracker.com/story/show/165655919}
     */
    if (queryParams.has('userType')) {
      const userType =
        queryParams.get('userType').toLowerCase() === USER_TYPES.PATIENT
          ? USER_TYPES.PATIENT
          : USER_TYPES.CLINICIAN;
      selectUserType(userType)(dispatch);
    }

    /**
     * Added for activation purposes
     * @see {@link https://constanttherapy.atlassian.net/browse/WEBAPP-284}
     * */
    if (queryParams.has('institutionId')) {
      setInstitutionId(parseInt(queryParams.get('institutionId')))(dispatch);
    }

    /**
     * Added according to BIANE signup flow requirements to store source user is coming from
     * @see {@link https://www.pivotaltracker.com/story/show/163429292}
     * It's also appeared to be useful for other leadsources @see {@link https://www.pivotaltracker.com/story/show/165655919}
     */
    if (queryParams.has('source')) {
      setSource(queryParams.get('source').toLowerCase())(dispatch);
    }

    /**
     * Added for activation purposes
     * @see {@link https://constanttherapy.atlassian.net/browse/WEBAPP-258}
     * */
    if (queryParams.has('email')) {
      setEmail(queryParams.get('email'))(dispatch);
    }

    /**
     * Added for activation purposes
     * @see {@link https://constanttherapy.atlassian.net/browse/WEBAPP-242}
     * */
    if (queryParams.has('invitationToken')) {
      setInvitationToken(queryParams.get('invitationToken'))(dispatch);
    }

    void logEvent({
      page: SIGNUP.SIGNUP,
      data: queryParams,
      nonInteraction: true,
    })(dispatch, store.getState.bind(store));
  }, [queryParams, dispatch]);

  useEffect(() => {
    if (signupData.institutionId) {
      void fetchPasswordRequirements(signupData.institutionId);
    }
  }, [signupData.institutionId]);

  return {
    signupData,
    passwordRequirements,
  };
}
