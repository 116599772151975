import queryString from 'query-string';
import { publicIpv4 } from 'public-ip';
import { Dispatch } from 'redux';

import { ANONYMOUS } from 'Shared/constants';
import { getBaseUrl } from 'Shared/utils';
import { fetchWrapper } from 'Shared/fetchWrapper';
import { LOG_EVENT } from 'Shared/types/redux';
import { RootState } from 'Shared/types/shared';

export const logLinkRouterEvent = (downloadDestination: string) => (dispatch: Dispatch) => {
  const qsParams = queryString.parse(location.search);
  const timestamp = Math.round(new Date().getTime() / 1000);

  return publicIpv4()
    .then(
      (response) => {
        return Promise.resolve(response);
      },
      () => {
        return Promise.resolve('1.1.1.1');
      }
    )
    .then((ip) => {
      return fetchWrapper.post(
        `${getBaseUrl({ apiSection: 'dashboard' })}/api/linkrouter/log`,
        {
          dispatch,
          types: LOG_EVENT,
        },
        {
          ip,
          destination: downloadDestination + '?' + location.search,
          referrer: document.referrer || qsParams['utm_source'] || 'download',
          user_agent: navigator.userAgent,
          metadata: qsParams.v || '', //Not sure what is it for but copied from BE for consistency
          timestamp: String(timestamp), //BE-requirement to pass it as a string
        },
        { skipAccessToken: true }
      );
    });
};

interface LogEventParams {
  page: string;
  userId?: number | string;
  token?: string;
  data?: object;
  nonInteraction?: boolean;
}

export const logEvent =
  (inputParams: LogEventParams) => (dispatch: Dispatch, getState: () => RootState) => {
    const userId =
      inputParams.userId ||
      (getState().user && getState().user.data && getState().user.data.user.userId) ||
      ANONYMOUS;
    //We give priority for passed-in parameter over the stored one
    const token =
      inputParams.token ||
      queryString.parse(location.search).token ||
      localStorage.getItem('accessToken');
    const anonymousId = (userId === ANONYMOUS && localStorage.getItem('anonymousId')) || null;

    const formattedParams = queryString.stringify(
      {
        eventSubType: inputParams.page,
        token,
        anonymousId,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    let requestBody = inputParams.data || {};

    /**
     * This condition should become truthful only in case when user visits a page with an invalid token
     * so event is logged as anonymous but token is still passed to the BE, so we can later reach out to
     * the customer in case they had any issues.
     * This is a pretty rare case, but we need to handle it.
     */
    if (anonymousId && token) {
      requestBody = { ...requestBody, unknownToken: token };
    }

    return fetchWrapper.post(
      `${getBaseUrl()}/api/user/${userId}/event/log/WebApp?${formattedParams}`,
      {
        dispatch,
        types: LOG_EVENT,
      },
      requestBody
    );
  };
