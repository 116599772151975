import React from 'react';
import ReactDOM from 'react-dom';

import { HTML_HEADER_ID } from 'Shared/constants';
import logoImage from 'Shared/src/themes/2023/images/logo.svg';

import styles from './header.module.scss';

const mountingPoint = document.getElementById(HTML_HEADER_ID);

export const Header = () => {
  return ReactDOM.createPortal(
    <img
      src={logoImage as string}
      alt="Constant Therapy"
      title="Constant Therapy"
      className={styles.logo}
    />,
    mountingPoint
  );
};
