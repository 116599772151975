import { FC, ReactNode } from 'react';
import { RouteProps } from 'react-router-dom';

import { User } from 'Shared/types/shared';

export enum USER_SUBSCRIPTION_STATUSES {
  TRIALING = 'trialing',
  TRIAL_ENDED = 'trialEnded',
  EXPIRED = 'expired',
  ACCOUNT_CREATED = 'account_created',
  CLINICIAN_SETUP = 'clinician_setup',
  PAST_DUE = 'past_due',
}

export const SUBSCRIPTION_REQUIRED_CUSTOMER_STATUSES = [
  USER_SUBSCRIPTION_STATUSES.TRIALING,
  USER_SUBSCRIPTION_STATUSES.TRIAL_ENDED,
  USER_SUBSCRIPTION_STATUSES.EXPIRED,
];

export const SUBSCRIPTION_REQUIRED_STRIPE_STATUSES = [
  USER_SUBSCRIPTION_STATUSES.TRIALING,
  USER_SUBSCRIPTION_STATUSES.TRIAL_ENDED,
  USER_SUBSCRIPTION_STATUSES.EXPIRED,
  USER_SUBSCRIPTION_STATUSES.ACCOUNT_CREATED,
  USER_SUBSCRIPTION_STATUSES.CLINICIAN_SETUP,
];

export const SUBSCRIPTION_STATUSES_WITH_CC_AVAILABLE = ['paying', 'trialing_with_cc'];

export const SUBSCRIPTION_PLANS_PERIODS = {
  DAYS: 'days',
  MONTHS: 'months',
  YEARS: 'years',
};

export const KNOWN_STRIPE_PLANS_PERIODS = ['monthly', 'yearly', 'two_year', 'three_year'];

export enum SUBSCRIBE_BUTTON_STATES {
  HIDE = 'hide',
  SUBSCRIBE = 'subscribe',
  CANCEL = 'cancel',
  UPDATE = 'update',
  RESUBSCRIBE = 'resubscribe',
}

export const PAYMENT_METHODS = {
  stripe: 'Stripe',
  apple: 'iOSIAP',
  amazon: 'AmazonIAP',
  google: 'GoogleIAP',
  flex: 'Flex',
  none: 'none',
};

export const US_STATES_LIST: Record<string, string> = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const CA_PROVINCES_LIST = {
  ON: 'Ontario',
  QC: 'Quebec',
  NC: 'Nova Scotia',
  NB: 'New Brunswick',
  MB: 'Manitoba',
  BC: 'British Columbia',
  PE: 'Prince Edward Island',
  SK: 'Saskatchewan',
  AB: 'Alberta',
  NL: 'Newfoundland and Labrador',
};

export const USER_TYPES = {
  CLINICIAN: 'clinician',
  PATIENT: 'patient',
};

export const PATIENT_TYPES = {
  NEW: 'new',
  EXISTING: 'existing',
};

export const BE_ERRORS_LIST = {
  NO_USER_FOUNT_TOKEN_ERROR: 'no-user-found-token-error',
  NO_USER_FOUNT_EMAIL_ERROR: 'no-user-found-email-error',
  NO_USER_FOUNT_USERNAME_ERROR: 'no-user-found-username-error',
  TOKEN_EXPIRED: 'Token has timed out',
  WEB_TOKEN_EXPIRED: 'Web token has timed out',
  NO_LOCATION: 'No location found for user',
};

export const TRIALING_PERIOD = 14;

export const FETCH_PARAMS_DEFAULTS = {
  method: 'GET',
  credentials: 'same-origin',
  mode: 'same-origin',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

export const USERS_LEADSOURCES = {
  flint: 'Flint Rehab',
};

export const PERFORMANCE_REPORT_SECTIONS_MAP = {
  domains: {
    title: 'Skill performance',
    link: '/skill-performance',
  },
  tasks: {
    title: 'Therapy performance',
    link: '/therapy-performance',
  },
  assessments: {
    title: 'Assessment Results',
    link: '/assessment-results',
  },
};

//TODO: display some meaningful message to a user if they were not permitted to login
//The only state we allow to login is `ACTIVATED`
export const USERS_STATUSES = {
  ACTIVE: 'ACTIVE', //User has accepted the invitation and license is active
  DECLINED: 'DECLINED', //User has declined the invitation (the flow is not implemented right now)
  EXPIRED: 'EXPIRED', //User has received invitation but neither accepted or declined yet
  INVITED: 'INVITED',
  SUSPENDED: 'SUSPENDED', //User is temporary suspended, but the license is still associated
};

export const COUNTRIES_LIST = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

export const CLINICIAN_ROLES = {
  SLP: 'Speech-language pathologist',
  occ_therapist: 'Occupational therapist',
  physical_therapist: 'Physical therapist',
  director: 'Director or administrator',
  neurologist: 'Neurologist',
  psychologist: 'Psychologist',
  other_physician: 'Other physician',
  teacher_or_professor: 'Teacher or professor',
  student: 'University or college student',
  OTHER: 'Other',
};

export const CLINICIAN_SETTING = {
  ACH: 'Acute care hospital',
  ALF: 'Assisted living facility',
  HOME: 'Homecare',
  IN_REH: 'Inpatient rehabilitation',
  MEM: 'Memory care',
  OUT_REH: 'Outpatient rehabilitation',
  SCHL: 'K-12 school',
  NURS: 'Skilled nursing facility',
  RCH: 'University clinic',
  OTHER: 'Other',
};

export const DEFAULT_ERROR =
  'Something went wrong. Please contact customer support at ' +
  '<a href="tel:+18882331399">1-888-233-1399</a> or ' +
  '<a href="mailto:support@constanttherapy.com">support@constanttherapy.com</a> if you need' +
  ' further assistance!';

export enum SURVEY_CAMPAIGNS {
  SURVEY_PILOT = 'survey_pilot',
  HOW_ARE_YOUR_TASKS = 'how_are_your_tasks',
  END_OF_TRIAL = 'end_of_trial',
}

export const SUPPORT = {
  PHONE: '+18882331399',
  PHONE_FORMATTED: '1-888-233-1399',
  EMAIL: 'support@constanttherapy.com',
};

export const DAILY_SESSION_NOTE_DETAILED_TYPE_ID = 'detailed';
export const DAILY_SESSION_NOTE_BRIEF_TYPE_ID = 'brief';

export enum REPORT_TYPES {
  PROGRESS_NOTE = 'progressNote',
  DISCHARGE_SUMMARY = 'dischargeSummary',
  DAILY_SESSION_NOTE = 'dailySessionNote',
}

export const ANONYMOUS = 'anonymous';

export enum AUTH_ACTIONS {
  PASSWORD = 'password',
  SSO = 'sso',
}

export const DOCUMENT_TITLE_BASE = 'Constant Therapy App';

export enum TaskTypes {
  TASK_TYPE = 'TaskType',
  SCHEDULED_TASK_TYPE = 'ScheduledTaskType',
  SCHEDULED_TASK_TYPE_STUB = 'ScheduledTaskTypeStub',
}

export const PARTNER_LOGO_STORAGE_NAME = 'partnerLogo';

export const PATIENT_BROCHURE_URL =
  'https://ct-info.s3.amazonaws.com/Constant-Therapy-Patient-Brochure.pdf';

export enum RouteAccessRestrictions {
  PRIVATE = 'private',
  PUBLIC = 'public',
  ONLY_PUBLIC = 'onlyPublic',
}

export interface EnhancedRouteProps extends RouteProps {
  access: RouteAccessRestrictions;
  layout: FC<{ children: ReactNode }>;
  module: FC;
}

export interface StripeCheckoutConstructor {
  configure: (options: StripeCheckoutConstructorConfig) => StripeCheckout;
}

export interface StripeCheckout {
  open: (options: { description: string }) => void;
}

export interface StripeCheckoutConstructorConfig {
  key: string;
  name: string;
  image: string;
  allowRememberMe: boolean;
  panelLabel: string;
  token: (stripeToken: { id: number; email: string }) => Promise<void>;
}

export const WEBSITE_URL = 'https://constanttherapyhealth.com';

export const HTML_HEADER_ID = 'header';
export const HTML_CONTENT_ID = 'content';
export const HTML_FOOTER_ID = 'footer';

export const MOBILE_APP_SOURCES = ['ios', 'android'];
