import { EnhancedRouteProps, RouteAccessRestrictions } from 'Shared/constants';
import { MainLayout, TherapyLayout, LandingLayout } from 'Shared/components/layout';

import { Download } from './download';
import { Start } from './start';
import { Survey } from './survey';
import { EnterpriseDashboard } from './enterpriseDashboard';
import { Activate } from './activate';
import { Pages } from './pages';
import { Subscribe } from './subscribe';
import { Dashboard } from './dashboard';
import { Account } from './account';
import { Unsubscribe } from './unsubscribe';
import { ForgotPassword } from './forgotPassword';
import { DoNotContact } from './doNotContact';
import { PaymentUpdate } from './paymentUpdate';
import { Retrial } from './retrial';
import { Signup } from './signup';
import { Login } from './login';
import { Therapy } from './therapy';

export const routes: EnhancedRouteProps[] = [
  {
    path: '/',
    access: RouteAccessRestrictions.PUBLIC,
    exact: true,
    layout: MainLayout,
    module: Pages,
  },
  {
    path: '/login',
    access: RouteAccessRestrictions.ONLY_PUBLIC,
    exact: true,
    layout: MainLayout,
    module: Login,
  },
  {
    path: '/login/:action',
    access: RouteAccessRestrictions.ONLY_PUBLIC,
    exact: false,
    layout: MainLayout,
    module: Login,
  },
  {
    path: '/signup',
    access: RouteAccessRestrictions.ONLY_PUBLIC,
    exact: false,
    layout: MainLayout,
    module: Signup,
  },
  {
    path: '/download',
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
    layout: MainLayout,
    module: Download,
  },
  {
    path: '/download-clinician',
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
    layout: MainLayout,
    module: Download,
  },
  {
    path: '/download-patient',
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
    layout: MainLayout,
    module: Download,
  },
  {
    path: '/start',
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
    layout: MainLayout,
    module: Start,
  },
  {
    path: '/survey/campaign/:campaignId',
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
    layout: LandingLayout,
    module: Survey,
  },
  {
    path: '/survey',
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
    layout: MainLayout,
    module: Survey,
  },
  {
    path: '/enterprise-dashboard',
    access: RouteAccessRestrictions.PRIVATE,
    exact: false,
    layout: MainLayout,
    module: EnterpriseDashboard,
  },
  {
    path: '/activate',
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
    layout: MainLayout,
    module: Activate,
  },
  {
    path: '/therapist-consult',
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
    layout: MainLayout,
    module: Pages,
  },
  {
    path: '/subscribe',
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
    layout: MainLayout,
    module: Subscribe,
  },
  {
    path: '/dashboard',
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
    layout: MainLayout,
    module: Dashboard,
  },
  {
    path: '/account',
    access: RouteAccessRestrictions.PRIVATE,
    exact: false,
    layout: MainLayout,
    module: Account,
  },
  {
    path: '/unsubscribe',
    access: RouteAccessRestrictions.PRIVATE,
    exact: false,
    layout: MainLayout,
    module: Unsubscribe,
  },
  {
    path: '/forgot-password',
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
    layout: MainLayout,
    module: ForgotPassword,
  },
  {
    path: '/do-not-contact',
    access: RouteAccessRestrictions.PUBLIC,
    exact: false,
    layout: MainLayout,
    module: DoNotContact,
  },
  {
    path: '/payment_update',
    access: RouteAccessRestrictions.PRIVATE,
    exact: false,
    layout: MainLayout,
    module: PaymentUpdate,
  },
  {
    path: '/retrial',
    access: RouteAccessRestrictions.ONLY_PUBLIC,
    exact: false,
    layout: MainLayout,
    module: Retrial,
  },
  {
    path: '/do-therapy',
    access: RouteAccessRestrictions.PRIVATE,
    exact: false,
    layout: TherapyLayout,
    module: Therapy,
  },
];
