import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useEffect, useState } from 'react';

import { RootState } from 'Shared/types/shared';
import { getUserData, verifyToken } from 'Shared/actions/user';
import { setUserIdToEvents } from 'Shared/src/scripts/firebase/analytics';

export function useRouteRenderer() {
  const dispatch: Dispatch = useDispatch();
  const authUser = useSelector((state: RootState) => state.user.data);

  const queryParams = queryString.parse(window.location.search);
  const formattedParams = queryString.stringify({
    returnUrl: document.location.pathname + '?' + queryString.stringify(queryParams),
  });

  const [isLoading, setIsLoading] = useState(!authUser);

  const fetchUserData = async (token?: string) => {
    try {
      if (token) {
        // Make sure that token passed via url-params is valid
        const response = await verifyToken(token)(dispatch);
        if (response) {
          // Set or replace token in localStorage if we got a valid response
          localStorage.setItem('accessToken', token);
        }
      }
      await getUserData(token)(dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!authUser) {
      void fetchUserData(queryParams.token as string);
    } else {
      setUserIdToEvents(authUser.user.userId.toString());
    }
  }, [queryParams.token, authUser, fetchUserData]);

  return { isLoading, authUser, formattedParams };
}
