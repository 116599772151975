import queryString from 'query-string';
import { Dispatch } from 'redux';

import { fetchWrapper } from 'Shared/fetchWrapper';
import { getBaseUrl } from 'Shared/utils';
import { SAVE_SURVEY_CAMPAIGN } from 'Shared/types/redux';
import {
  SurveyCampaignEndOfTrialForm,
  SurveyCampaignHowAreYourTasksForm,
} from 'Shared/types/forms';
import { SURVEY_CAMPAIGNS } from 'Shared/constants';

type SurveyCampaignData = (SurveyCampaignHowAreYourTasksForm | SurveyCampaignEndOfTrialForm) & {
  campaign: SURVEY_CAMPAIGNS;
  isFinalStep: boolean;
};

export const saveSurveyCampaign =
  ({ token, data }: { token: string; data?: SurveyCampaignData }) =>
  async (dispatch: Dispatch) => {
    const formattedParams = queryString.stringify(
      { token },
      {
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return await fetchWrapper.post(
      `${getBaseUrl({ apiSection: 'dashboard' })}/api/survey/results?${formattedParams}`,
      {
        dispatch,
        types: SAVE_SURVEY_CAMPAIGN,
      },
      data,
      { isSubmission: true, skipAccessToken: true }
    );
  };
