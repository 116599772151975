import { useCallback, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Dispatch } from 'redux';
import { useParams } from 'react-router-dom';

// import { useReactToPrint } from 'react-to-print';

import { usePrintDocument } from 'Shared/hooks/usePrintDocument';
import { logEvent } from 'Shared/actions/event';
import {
  BE_REPORT_TRUE_FALSE,
  getDailySessionReport,
  getPatientReport,
} from 'Shared/actions/patient';
import { REPORT_TYPES } from 'Shared/constants';
import { DASHBOARD } from 'Shared/types/events';
import { RootState, User } from 'Shared/types/shared';
import { DASHBOARD_EVENTS, trackEvent } from 'Shared/src/scripts/firebase/analytics';

interface NoteParams {
  startDate?: string;
  endDate?: string;
  date?: string;
}

interface UseNoteProps {
  patient: User;
  reportType: REPORT_TYPES;
  ignoreStartDateForBaseline?: 0 | 1;
}

export function useNote({ patient, reportType, ignoreStartDateForBaseline = 0 }: UseNoteProps) {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const report = useSelector((state: RootState) => state.patient.report);
  const { startDate, endDate, date } = useParams<NoteParams>();
  const componentRef = useRef<HTMLDivElement>();
  const [isLoading, setIsLoading] = useState(true);
  const [textToCopy, setTextToCopy] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement>(null);
  const { handlePrint } = usePrintDocument({
    onBeforePrint: () => {
      trackEvent(DASHBOARD_EVENTS.PRINT_PATIENT_REPORT);

      void logEvent({
        page: DASHBOARD.PATIENT_VIEW_PATIENT_REPORT,
        data: {
          object_type: 'button',
          action: 'print',
          type: reportType,
        },
      })(dispatch, store.getState.bind(store));
    },
  });

  const fetchPatientReport = async ({
    startDate,
    endDate,
    date,
    patient,
    reportType,
  }: {
    startDate: string;
    endDate: string;
    date: string;
    patient: User;
    reportType: REPORT_TYPES;
  }) => {
    const requestParams = {
      patientId: patient.user.userId,
      token: localStorage.getItem('accessToken'),
      assistedOnly: reportType.includes(REPORT_TYPES.DAILY_SESSION_NOTE)
        ? BE_REPORT_TRUE_FALSE.TRUE
        : BE_REPORT_TRUE_FALSE.FALSE,
    };

    setIsLoading(true);

    try {
      if (reportType === REPORT_TYPES.DAILY_SESSION_NOTE) {
        await getDailySessionReport({ ...requestParams, date })(dispatch);
      } else {
        await getPatientReport({
          ...requestParams,
          ignoreStartDateForBaseline,
          startDate,
          endDate,
        })(dispatch);
      }
    } catch (e: unknown) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = useCallback(() => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    trackEvent(DASHBOARD_EVENTS.COPY_PATIENT_REPORT);

    void logEvent({
      page: DASHBOARD.PATIENT_VIEW_PATIENT_REPORT,
      data: {
        object_type: 'button',
        action: 'copy',
        type: reportType,
      },
    })(dispatch, store.getState.bind(store));
  }, [report]);

  /**
   * TODO: For some reason this one stopped working so I had to implement handwritten one
   * need to review what was wrong and revive this functionality
   */
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   onBeforePrint: () => {
  //     void logEvent({
  //       page: DASHBOARD.PATIENT_VIEW_PATIENT_REPORT,
  //       data: {
  //         object_type: 'button',
  //         action: 'download',
  //         type: reportType,
  //       },
  //     })(dispatch, store.getState.bind(store));
  //   },
  // });

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
    ],
    placement: 'left',
  });

  useEffect(() => {
    void fetchPatientReport({ startDate, endDate, date, patient, reportType });
  }, [startDate, endDate, date, patient, reportType]);

  useEffect(() => {
    void logEvent({
      page: DASHBOARD.PATIENT_VIEW_PATIENT_REPORT,
      data: {
        object_type: 'button',
        action: 'view',
        type: reportType,
      },
    })(dispatch, store.getState.bind(store));
  }, [reportType]);

  useEffect(() => {
    if (componentRef.current) {
      setTextToCopy(componentRef.current.innerText);
    }
  }, [componentRef.current]);

  return {
    isLoading,
    report,
    startDate,
    endDate,
    componentRef,
    textToCopy,
    handleCopy,
    isCopied,
    handlePrint,
    popper: {
      ref: setPopperElement,
      style: styles.popper,
      attributes: attributes.popper,
    },
    arrow: {
      ref: setArrowElement,
      style: styles.arrow,
    },
    setReferenceElement,
  };
}
