import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { SURVEY_CAMPAIGNS } from 'Shared/constants';
import { saveSurveyCampaign } from 'Shared/actions/survey';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { SURVEY_PHASE, SurveyCampaignHowAreYourTasksForm } from 'Shared/types/forms';

export function useHowAreYourTasks() {
  const queryParams = useQueryParams();
  const dispatch: Dispatch = useDispatch();

  const [page, setPage] = useState(1);

  const handleSubmit = async (formData: SurveyCampaignHowAreYourTasksForm) => {
    await saveSurveyCampaign({
      token: queryParams.get('token') || localStorage.getItem('accessToken'),
      data: {
        ...formData,
        campaign: SURVEY_CAMPAIGNS.HOW_ARE_YOUR_TASKS,
        isFinalStep: true,
      },
    })(dispatch);
    setPage(page + 1);
  };

  return {
    token: queryParams.get('token'),
    phase: queryParams.get('phase') as SURVEY_PHASE,
    satisfaction: queryParams.get('satisfaction'),
    page,
    setPage,
    handleSubmit,
  };
}
