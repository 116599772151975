export const LOGIN_FORM_NAME = 'loginWizard';
export const SURVEY_CAMPAIGN_FORM_NAME = 'surveyCampaignWizard';
export const PATIENT_ACTIVATION_FORM_NAME = 'enterprisePatientActivationWizard';
export const CHANGE_PASSWORD_FORM_NAME = 'changePasswordForm';

export interface LoginForm {
  username: string;
  password?: string;
  userId?: number;
  otp?: string;
  otpResetToken?: string;
}

export interface SurveyCampaignHowAreYourTasksForm {
  satisfaction: string;
  phase: SURVEY_PHASE;
  difficulty: SURVEY_DIFFICULTY;
  campaign: string;
  additionalInfo?: string;
  quote_consent?: boolean;
  isFinalStep?: boolean;
}

export interface SurveyCampaignEndOfTrialForm {
  feedback: string[];
  otherMessage?: string;
  extra?: string;
  campaign: string;
}

export interface ActivatePatientForm {
  username: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  gender: string;
  education: string;
  age: string;
  conditions: string[];
  conditionsPeriod: string;
  areas: string[];
}

export interface AddExistingPatientForm {
  username: string;
  password: string;
}

export interface AddNewPatientForm extends AddExistingPatientForm {
  gender: string;
  education: string;
  age: string;
  conditions: string[];
  conditionsPeriod: string;
  areas: string[];
}

export interface ChangePasswordForm {
  current_password: string;
  new_password: string;
  new_password_match: string;
}

export interface ActivatePatientHWInvitationForm {
  email: string;
  password: string;
  careGiver: boolean;
  acceptedEULA: boolean;
  action: string;
}

export type SURVEY_PHASE = '0' | '1' | '2' | '3';
export type SURVEY_DIFFICULTY = 'easy' | 'perfect' | 'hard';
