import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';

import logoImage from 'Shared/src/themes/2023/images/logo.svg';
import { HTML_HEADER_ID, SUPPORT } from 'Shared/constants';
import { FullscreenMobileMenu } from 'Shared/components/layout/fullscreenMobileMenu';

import styles from './index.module.scss';

const mountingPoint = document.getElementById(HTML_HEADER_ID);

export const Header: FC = () => {
  return ReactDOM.createPortal(
    <nav className={styles.header}>
      <ul className={styles.menu}>
        <li>
          <FullscreenMobileMenu
            title="Help"
            launcher={
              <button className={styles.helpMenuLauncher}>
                <FontAwesomeIcon icon={faQuestionCircle} className={styles.icon} />
                Help
              </button>
            }
            className={styles.helpMenu}
          >
            <ul className={styles.helpMenuList}>
              <li>Call us at {SUPPORT.PHONE_FORMATTED}</li>
              <li>Email us: {SUPPORT.EMAIL}</li>
            </ul>
          </FullscreenMobileMenu>
        </li>
        <li>
          <a href="/" className={styles.logoWrapper}>
            <img
              src={logoImage as string}
              alt="Constant Therapy"
              title="Constant Therapy"
              className={styles.logo}
            />
          </a>
        </li>
        <li>
          <FullscreenMobileMenu
            title="Settings"
            side="right"
            launcher={
              <button className={styles.settingsMenuLauncher}>
                <FontAwesomeIcon icon={faChartSimple} className={styles.icon} />
                Settings
              </button>
            }
            className={styles.settingsMenu}
          >
            <ul className={styles.settingsMenuList}>
              <li>Use self-guided mode</li>
              <li>Therapy Settings</li>
              <li>Audio Settings</li>
            </ul>
          </FullscreenMobileMenu>
        </li>
      </ul>
    </nav>,
    mountingPoint
  );
};
