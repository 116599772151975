import { useCallback, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';
import { DecoratedFormProps } from 'redux-form';
import md5 from 'md5';
import { useHistory } from 'react-router-dom';

import { logEvent } from 'Shared/actions/event';
import { GA_EVENTS, SIGNUP } from 'Shared/types/events';
import { getUserIp } from 'Shared/utils';
import { clearUserType, signupClinician } from 'Shared/actions/signup';
import { PARTNER_LOGO_STORAGE_NAME } from 'Shared/constants';
import { SignupData, USER_TYPES } from 'Shared/types/shared';
import { getUserData } from 'Shared/actions/user';
import { BUILT_IN_EVENTS, trackEvent } from 'Shared/src/scripts/firebase/analytics';

import { ClinicianSignupForm, CLINICIAN_SIGNUP_TOTAL_STEPS } from '../../constants';

interface useWizardProps {
  signupData: SignupData;
}

export function useWizard({ signupData }: useWizardProps) {
  const store = useStore();
  const dispatch: Dispatch = useDispatch();
  const history = useHistory();
  const isInstitutionBasedSignup = Boolean(signupData.institutionId && signupData.email);
  const [page, setPage] = useState(1);
  const totalSteps = isInstitutionBasedSignup
    ? CLINICIAN_SIGNUP_TOTAL_STEPS.INSTITUTION
    : CLINICIAN_SIGNUP_TOTAL_STEPS.REGULAR;

  const handleClinicianSignup = async (
    formData: ClinicianSignupForm,
    dispatch: Dispatch,
    formProps: DecoratedFormProps
  ) => {
    trackEvent(BUILT_IN_EVENTS.SIGNUP, { type: USER_TYPES.CLINICIAN });

    void logEvent({
      page: SIGNUP.CLINICIAN_SIGNED_UP,
      nonInteraction: false,
    })(dispatch, store.getState.bind(store));

    const clientIpAddress = await getUserIp();
    const signupClinicianData = {
      ...formData,
      platform: 'browser',
      clientIpAddress,
      requestId: md5(JSON.stringify(formData)),
    };
    const clinicianSignupResult = await signupClinician(signupClinicianData)(dispatch);
    formProps.destroy();
    localStorage.setItem('accessToken', clinicianSignupResult.data.token);
    //Cleans up `partnerLogo` from storage once user successfully signed up
    sessionStorage.removeItem(PARTNER_LOGO_STORAGE_NAME);

    await getUserData(clinicianSignupResult.data.token)(dispatch);

    // We need to redirect activated and authenticated clinician back to activation
    // screen in order to display activation confirmation, before they actually get to
    // dashboard, see https://constanttherapy.atlassian.net/browse/WEBAPP-201
    if (formData.invitationToken && !formData.institutionId) {
      history.push(`/activate?t=${formData.invitationToken}`);
    }

    history.push('/dashboard');
  };

  const handleSubmit = useCallback(
    async (formData: ClinicianSignupForm, dispatch: Dispatch, formProps: DecoratedFormProps) => {
      switch (page) {
        case 1:
          trackEvent(BUILT_IN_EVENTS.PAGE_VIEW, { page_path: SIGNUP.CLINICIAN_BASIC });

          void logEvent({
            page: SIGNUP.CLINICIAN_BASIC,
            nonInteraction: true,
          })(dispatch, store.getState.bind(store));
          setPage(page + 1);
          break;
        case 2:
          trackEvent(BUILT_IN_EVENTS.PAGE_VIEW, { page_path: SIGNUP.CLINICIAN_ADVANCED });

          void logEvent({
            page: SIGNUP.CLINICIAN_ADVANCED,
            nonInteraction: true,
          })(dispatch, store.getState.bind(store));

          if (totalSteps === CLINICIAN_SIGNUP_TOTAL_STEPS.INSTITUTION) {
            return await handleClinicianSignup(formData, dispatch, formProps);
          } else {
            setPage(page + 1);
          }

          break;
        case 3:
          trackEvent(BUILT_IN_EVENTS.PAGE_VIEW, { page_path: SIGNUP.CLINICIAN_EXTRA });

          void logEvent({
            page: SIGNUP.CLINICIAN_EXTRA,
            nonInteraction: true,
          })(dispatch, store.getState.bind(store));

          return await handleClinicianSignup(formData, dispatch, formProps);
      }
    },
    [totalSteps, page]
  );

  const handlePrevious = () => {
    if (page === 1) {
      return clearUserType()(dispatch);
    }
    setPage(page - 1);
  };

  return {
    page,
    totalSteps,
    isInstitutionBasedSignup,
    handleSubmit,
    handlePrevious,
  };
}
