import { useEffect } from 'react';
import queryString from 'query-string';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { logEvent } from 'Shared/actions/event';
import { SUBSCRIPTION } from 'Shared/types/events';
import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { SUBSCRIPTION_EVENTS, trackEvent } from 'Shared/src/scripts/firebase/analytics';

import { ValidateUserForm } from './constants';

export function useValidateUserModal() {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const queryParams = useQueryParams();

  const emailOrUsername = queryParams.get('emailOrUsername');
  const source = queryParams.get('source');

  const handleSubmit = (formData: ValidateUserForm) => {
    const qsParams = {
      ...queryString.parse(document.location.search),
      emailOrUsername: formData.emailOrUsername,
    };
    document.location.assign(document.location.pathname + '?' + queryString.stringify(qsParams));
  };

  useEffect(() => {
    trackEvent(SUBSCRIPTION_EVENTS.VALIDATE_USER);

    void logEvent({ page: SUBSCRIPTION.VALIDATE_USER, data: { source } })(
      dispatch,
      store.getState.bind(store)
    );
  }, []);

  return {
    handleSubmit,
    emailOrUsername,
  };
}
