import React, { FC } from 'react';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { routes } from 'App/routes';

import store from 'Shared/store';
import { RootState } from 'Shared/types/shared';
import { MainLayout } from 'Shared/components/layout';
import { RouteRenderer } from 'Shared/components/layout/router/routeRenderer';
import { PageError } from 'Shared/components/pageError';

export const App: FC = () => {
  return (
    <Provider store={store as Store<RootState>}>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <BrowserRouter>
          <Switch>
            {routes.map((enhancedRoute, i) => {
              const { module: Module, layout: Layout, access, ...route } = enhancedRoute;
              return (
                <Route key={i} {...route}>
                  <RouteRenderer access={access} Layout={Layout || MainLayout} Module={Module} />
                </Route>
              );
            })}
            <Route path="/clinician/login">
              <Redirect to="/login" />
            </Route>
            <Route path="/reports">
              <Redirect to="/dashboard" />
            </Route>
            <Route path="*">
              <MainLayout>
                <PageError />
              </MainLayout>
            </Route>
          </Switch>
        </BrowserRouter>
      </DndProvider>
    </Provider>
  );
};
