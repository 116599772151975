import { useCallback, useState } from 'react';
import { Dispatch } from 'redux';
import { DecoratedFormProps } from 'redux-form';
import md5 from 'md5';
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { clearUserType } from 'Shared/actions/signup';
import { logEvent } from 'Shared/actions/event';
import { GA_EVENTS, SIGNUP } from 'Shared/types/events';
import { getUserIp } from 'Shared/utils';
import { signupPatient } from 'Shared/actions/signup';
import { PARTNER_LOGO_STORAGE_NAME } from 'Shared/constants';
import { getUserData } from 'Shared/actions/user';
import { SignupData, USER_TYPES } from 'Shared/types/shared';
import { BUILT_IN_EVENTS, trackEvent } from 'Shared/src/scripts/firebase/analytics';

import { PATIENT_SIGNUP_TOTAL_STEPS, PatientSignupForm } from '../../constants';

interface useWizardProps {
  signupData: SignupData;
}

export function useWizard({ signupData }: useWizardProps) {
  const store = useStore();
  const dispatch: Dispatch = useDispatch();
  const history = useHistory();
  const isInstitutionBasedSignup = Boolean(signupData.institutionId && signupData.email);
  const [page, setPage] = useState(isInstitutionBasedSignup ? 2 : 1);
  const totalSteps = isInstitutionBasedSignup
    ? PATIENT_SIGNUP_TOTAL_STEPS.INSTITUTION
    : PATIENT_SIGNUP_TOTAL_STEPS.REGULAR;

  const handlePatientSignup = async (
    formData: PatientSignupForm,
    dispatch: Dispatch,
    formProps: DecoratedFormProps
  ) => {
    trackEvent(BUILT_IN_EVENTS.SIGNUP, { type: USER_TYPES.PATIENT });

    void logEvent({
      page: SIGNUP.PATIENT_SIGNED_UP,
      nonInteraction: false,
    })(dispatch, store.getState.bind(store));

    /**
     *     https://constanttherapy.atlassian.net/browse/WEBAPP-612 - had to make this change because of the logic discrepancy with BE, see below
     *     public String assistedStatusToLeadSource()
     *         {
     *             switch (this.clinicianAssisted) {
     *                 case 1:
     *                     return "INET";
     *                 case 0:
     *                     return "HLTH";
     *                 default:
     *                     return "NA";
     *             }
     *         }
     */
    const { webClinicianAssisted, ...cleanFormData } = formData;

    const clientIpAddress = await getUserIp();
    const signupPatientData = {
      ...cleanFormData,
      clinicianAssisted: parseInt(webClinicianAssisted) ? 0 : 1,
      platform: 'browser',
      clientIpAddress,
      requestId: md5(JSON.stringify(formData)),
    };

    const patientSignupResult = await signupPatient(signupPatientData)(dispatch);
    formProps.destroy();
    localStorage.setItem('accessToken', patientSignupResult.data.token);

    //Cleans up `partnerLogo` from storage once user successfully signed up
    sessionStorage.removeItem(PARTNER_LOGO_STORAGE_NAME);

    await getUserData(patientSignupResult.data.token)(dispatch);

    history.push('/subscribe?source=signup');
  };

  const handleSubmit = useCallback(
    async (formData: PatientSignupForm, dispatch: Dispatch, formProps: DecoratedFormProps) => {
      switch (page) {
        case 1:
          trackEvent(BUILT_IN_EVENTS.PAGE_VIEW, { page_path: SIGNUP.PATIENT_BASIC });

          void logEvent({
            page: SIGNUP.PATIENT_BASIC,
            nonInteraction: true,
          })(dispatch, store.getState.bind(store));
          setPage(page + 1);
          break;
        case 2:
          trackEvent(BUILT_IN_EVENTS.PAGE_VIEW, { page_path: SIGNUP.PATIENT_ADVANCED });

          void logEvent({
            page: SIGNUP.PATIENT_ADVANCED,
            nonInteraction: true,
          })(dispatch, store.getState.bind(store));
          setPage(page + 1);
          break;
        case 3:
          trackEvent(BUILT_IN_EVENTS.PAGE_VIEW, { page_path: SIGNUP.PATIENT_EXTRA });

          void logEvent({
            page: SIGNUP.PATIENT_EXTRA,
            nonInteraction: true,
          })(dispatch, store.getState.bind(store));
          return await handlePatientSignup(formData, dispatch, formProps);
      }
    },
    [page]
  );

  const handlePrevious = () => {
    if (page === 1) {
      return clearUserType()(dispatch);
    }
    setPage(page - 1);
  };

  return {
    page,
    totalSteps,
    isInstitutionBasedSignup,
    handleSubmit,
    handlePrevious,
  };
}
