import React, { ReactNode } from 'react';

import { Header } from './header';
import { useLandingLayout } from './useLandingLayout';

export const LandingLayout = ({ children }: { children: ReactNode }) => {
  useLandingLayout();

  return (
    <>
      <Header />
      {children}
    </>
  );
};
