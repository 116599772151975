import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';

import { User } from 'Shared/types/shared';

import { app } from './app';

const analytics = getAnalytics(app);

export enum SUBSCRIPTION_EVENTS {
  VALIDATE_USER = 'validate_user',
  CHOOSE_PLAN_LATER = 'choose_plan_later',
}

export enum BUILT_IN_EVENTS {
  OUTBOUND_CLICK = 'outbound_click',
  LOGIN = 'login',
  SIGNUP = 'sign_up',
  BEGIN_CHECKOUT = 'begin_checkout',
  PAGE_VIEW = 'page_view',
}

export enum COMMON_EVENTS {
  VIDEO_START = 'video_start',
  FILE_DOWNLOAD = 'file_download',
}

export enum SIGNUP_EVENTS {
  USER_TYPE_SELECTED = 'user_type_selected',
}

export enum DASHBOARD_EVENTS {
  INITIAL_POPUP = 'dashboard_initial_popup',
  PRINT_PATIENT_REPORT = 'print_patient_report',
  COPY_PATIENT_REPORT = 'copy_patient_report',
}

export const setUserInfo = (user: User) =>
  setUserProperties(analytics, {
    email: user.user.email,
  });

export const setUserIdToEvents = (id: string) => setUserId(analytics, id);

export const trackEvent = (
  eventName: string,
  eventParams?: {
    [key: string]: any;
  }
) => logEvent(analytics, eventName, eventParams);
