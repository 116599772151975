import { useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';

import { useQueryParams } from 'Shared/hooks/useQueryParams';
import { saveSurveyCampaign } from 'Shared/actions/survey';
import { SURVEY_CAMPAIGNS } from 'Shared/constants';
import { SurveyCampaignEndOfTrialForm } from 'Shared/types/forms';

export function useEndOfTrial() {
  const queryParams = useQueryParams();
  const dispatch: Dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (formData: SurveyCampaignEndOfTrialForm) => {
    await saveSurveyCampaign({
      token: queryParams.get('token') || localStorage.getItem('accessToken'),
      data: {
        ...formData,
        campaign: SURVEY_CAMPAIGNS.END_OF_TRIAL,
        isFinalStep: true,
      },
    })(dispatch);
    setIsSubmitted(true);
  };

  return {
    isSubmitted,
    handleSubmit,
  };
}
