import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { logEvent } from 'Shared/actions/event';
import { GA_EVENTS, SUBSCRIPTION } from 'Shared/types/events';
import { SubscriptionInfo } from 'Shared/types/shared';
import { useQueryParams } from 'Shared/hooks/useQueryParams';

export interface SubscribedUserModalProps {
  displayName: string;
  subscriptionInfo: SubscriptionInfo;
  isAuthorized: boolean;
}

export function useSubscribedUserModal({
  displayName,
  subscriptionInfo,
  isAuthorized,
}: SubscribedUserModalProps) {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const queryParams = useQueryParams();

  const source = queryParams.get('source');

  useEffect(() => {
    void logEvent({
      page: SUBSCRIPTION.ALREADY_SUBSCRIBED,
      data: { userType: subscriptionInfo.userType, displayName, source },
      nonInteraction: true,
    })(dispatch, store.getState.bind(store));
  }, [subscriptionInfo, dispatch]);
}
