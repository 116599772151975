import { useState, useEffect } from 'react';
import Cookie from 'js-cookie';
import { useDispatch, useStore } from 'react-redux';
import { Dispatch } from 'redux';

import { logEvent } from 'Shared/actions/event';
import { DASHBOARD } from 'Shared/types/events';
import { ClinicianDashboard } from 'Shared/types/shared';
import { COMMON_EVENTS, DASHBOARD_EVENTS, trackEvent } from 'Shared/src/scripts/firebase/analytics';
import { PATIENT_BROCHURE_URL } from 'Shared/constants';

export function usePatientsList({ dashboardData }: { dashboardData: ClinicianDashboard }) {
  const dispatch: Dispatch = useDispatch();
  const store = useStore();
  const [searchText, setSearchText] = useState('');
  const [isPopupShown, setIsPopupShown] = useState(
    !dashboardData.Summary.homeExercises && !Cookie.get('isDashboardVisited')
  );

  const downloadPDFClickHandler = () => {
    trackEvent(COMMON_EVENTS.FILE_DOWNLOAD, { file: PATIENT_BROCHURE_URL });

    void logEvent({ page: DASHBOARD.INITIAL_BROCHURE_DOWNLOAD })(
      dispatch,
      store.getState.bind(store)
    );
  };

  useEffect(() => {
    if (isPopupShown) {
      trackEvent(DASHBOARD_EVENTS.INITIAL_POPUP);

      void logEvent({ page: DASHBOARD.INITIAL_POPUP })(dispatch, store.getState.bind(store));
      Cookie.set('isDashboardVisited', 'true');
    }
  }, [isPopupShown]);

  return {
    searchText,
    setSearchText,
    isPopupShown,
    hidePopup: () => {
      setIsPopupShown(false);
    },
    downloadPDFClickHandler,
  };
}
